<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" static class="fixed inset-0 z-40 overflow-y-auto" @close="cancel" :open="open">

            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="flex flex-col h-full px-8 py-4 bg-white">
                    <!--  -->
                    <div class="flex flex-col mb-4">
                        <DialogTitle as="h3" class="pl-1 text-lg font-medium leading-6 text-gray-900">
                            {{ $t("configuration.editJson") }}
                        </DialogTitle>

                        <!-- TABS -->
                        <div class="grid grid-cols-1 mt-4 sm:hidden">
                            <select @change="handleTabChange" aria-label="Select a tab" class="w-full col-start-1 row-start-1 py-2 pl-3 pr-8 text-base text-gray-900 bg-white rounded-md appearance-none outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600">
                                <option v-for="tab in tabs" :key="tab.id" :selected="tab.current">{{ tab.name }}</option>
                            </select>
                            <ChevronDownIcon class="self-center w-6 h-6 col-start-1 row-start-1 mr-2 pointer-events-none size-5 justify-self-end fill-gray-500" aria-hidden="true" />
                        </div>
                        <div class="hidden sm:block">
                            <div class="border-b border-gray-200">
                                <nav class="flex -mb-px space-x-8 cursor-pointer" aria-label="Tabs">
                                    <div @click="handleTabChange" v-for="tab in tabs" :key="tab.id" :class="[tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 pt-4 pb-2 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</div>
                                </nav>
                            </div>
                        </div>

                    </div>
                    <!-- DATA -->
                    <div class="flex-grow h-full overflow-auto border-2">

                        <div v-show="tabs.find((tab) => tab.current).id == 'config'" class="w-full h-full">
                            <JsonEditorVue class="w-full h-full border-b-2 border-gray-200 whitespace-nowrap" v-model="config" v-bind="{ mode: 'text', mainMenuBar: false }" />
                        </div>

                        <div v-show="tabs.find((tab) => tab.current).id == 'state'" class="w-full h-full">
                            <JsonEditorVue readOnly class="w-full h-full min-h-full border-b-2 border-gray-200 whitespace-nowrap" v-model="states" v-bind="{ mode: 'text', mainMenuBar: false }" />
                        </div>

                        <div v-show="tabs.find((tab) => tab.current).id == 'alarms'" class="w-full h-full">
                            <JsonEditorVue readOnly class="w-full h-full border-b-2 border-gray-200 whitespace-nowrap" v-model="alarms" v-bind="{ mode: 'text', mainMenuBar: false }" />
                        </div>

                    </div>

                    <!--  -->
                    <div class="flex items-center justify-end mt-4 h-28 sm:flex-row">
                        <div class="w-full sm:flex sm:flex-row-reverse">
                            <button v-if="tabs.find((tab) => tab.current).id == 'config'" type="button" class="inline-flex justify-center w-full h-10 px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:bg-gray-300 hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" @click="$emit('approve', config)" :disabled="!isValid">
                                {{ $t("common.action.save") }}
                            </button>
                            <button type="button" class="inline-flex justify-center w-full h-10 px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="cancel" ref="cancelButtonRef">
                                {{ $t("common.action.cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
            </TransitionChild>

        </Dialog>
    </TransitionRoot>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";

import {
    Dialog, DialogTitle, TransitionChild, TransitionRoot
} from "@headlessui/vue";

import JsonEditorVue from 'json-editor-vue'

export default {
    components: {
        Dialog,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        JsonEditorVue,
        ChevronDownIcon
    },

    props: ["open"],
    emits: ["approve", "cancel"],

    setup(props, { emit }) {

        const tabs = ref([
            { id: 'config', name: 'Configuration', current: true },
            { id: 'state', name: 'Device States', current: false },
            { id: 'alarms', name: 'Device Alarms', current: false },
        ]);

        const store = useStore();
        const device = computed(() => store.state.device.current);
        const config = ref(device.value.configV2);
        const states = { device_state: device.value.stateAlarms.device_state, device_state_updated: device.value.stateAlarms.device_state_updated }
        const alarms = {
            device_reported_alarms: device.value.stateAlarms.device_reported_alarms,
            device_reported_alarms_updated: device.value.stateAlarms.device_reported_alarms_updated,
            cloud_reported_alarms: device.value.stateAlarms.cloud_reported_alarms,
            cloud_reported_alarms_version: device.value.stateAlarms.cloud_reported_alarms_version,
            cloud_reported_alarms_session_identifier: device.value.stateAlarms.cloud_reported_alarms_session_identifier
        }

        watch(device, () => {
            if (device.value) {
                config.value = device.value.configV2;
            }
        })

        const isValid = computed(() => {
            try {
                JSON.parse(config.value);
            } catch (e) {
                return false;
            }
            return true;
        });

        const cancel = () => {
            config.value = device.value.configV2
            tabs.value.forEach((tab) => tab.current = false);
            tabs.value[0].current = true;

            emit('cancel')
        }

        const handleTabChange = (event) => {

            let selectedTab = event.target.value;
            if (!selectedTab) selectedTab = event.target.innerText;

            tabs.value.forEach(tab => {
                tab.current = (tab.name === selectedTab);
            });
        };


        return {
            config,
            states,
            alarms,
            cancel,
            isValid,
            tabs,
            handleTabChange
        };
    },
};
</script>
<style>
.hljs .header {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}
</style>
